import React, { useContext, useState, useEffect } from 'react'
import { graphql } from "gatsby"
import Form from 'components/form'
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "context/context"

import { cloneDeep } from 'lodash'

import Layout from "components/layout"
import SEO from 'components/seo'
import Contacts from 'components/contacts'
import FlexibleContent from "components/flexibleContent"

import Swal from 'sweetalert2'

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                contactFields {
                    title
                    hideContacts
                    contacts {
                        title
                        copy
                        contactGroup {
                            title
                            name
                            address
                            logo {
                            mediaItemUrl
                            }
                            contacts {
                                name
                                mobile
                                email
                            }
                        }
                    }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
            form(id: "Zm9ybTo0NDY") {
                ...FormFieldsFragment
            }
        }
    }
`

export default ({
    location,
    data
}) => {

    const {
        options: {
            copy = {}
        },
        options,
    } = useContext(MainContext)

    const [fieldValues, setFieldValues] = useState({})

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)

        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const [submitForm] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)

    const {
        page: {
            contactFields: fields
        },
        form
    } = data.siteFields

    const {
        databaseId,
        flexibleContent,
    } = data.siteFields.page

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitForm({
            variables: {
                data: json,
                type: 'CONTACT'
            },
        }).then(response => {
            setIsLoading(false)

            Swal.fire({
                icon: 'success',
                title: 'Thanks for that',
                text: `We've received your submission, and will be in touch shortly.`,
            })

            setFieldValues({})
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    const {
        title,
        contacts,
        hideContacts,
    } = fields

    return (
        <Layout location={location}>
            <SEO title='Contact Us' />
            <div className="contact">
                <div className="container">
                    <div className="contact-form">
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                        <ul className="contact-form-details">
                            <li>
                                <a href={'tel:' + options.phone} dangerouslySetInnerHTML={{ __html: options.phone }} />
                            </li>
                            <li>
                                <a href={'mailto:' + options.emailAddress} dangerouslySetInnerHTML={{ __html: options.emailAddress }} />
                            </li>
                        </ul>
                        <Form
                            form={form}
                            id="Zm9ybTo0NDY="
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                            fieldValues={fieldValues}
                            handleChange={handleChange}
                        />
                    </div>
                    { !hideContacts &&
                        <div className="contact-details">
                            <Contacts
                                title={contacts.title}
                                copy={contacts.copy}
                                contactGroup={contacts.contactGroup}
                            />
                        </div>
                    }
                </div>
            </div>
            <FlexibleContent
                id={databaseId}
                content={flexibleContent}
            />
        </Layout>

    )
}
